import "./AuthGate.scss";
import { createPermissionChecker } from "./auth";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import React, { createContext, useContext } from "react";

export const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

const components = {
  SignIn: {
    Header() {
      return <h1 className="mx-3 px-3 mt-4 mb-0">Welcome to i-RAT</h1>;
    },
  },
  SignUp: {
    Header() {
      return (
        <>
          <h1 className="mx-3 px-3 mt-4 mb-0">Register for a Free Trial</h1>
          <p className="mx-3 px-3 mt-2">
            Trial accounts only have limited functionality and access to irrigation features.
          </p>
        </>
      );
    },
  },
};

export default function AuthGate({ children }) {
  return (
    <div className="irat-auth">
      <Authenticator signUpAttributes={["email"]} components={components}>
        {(auth) => (
          <AuthContext.Provider
            value={{
              ...auth,
              checkPermission: createPermissionChecker(auth.user),
            }}
          >
            {children}
          </AuthContext.Provider>
        )}
      </Authenticator>
    </div>
  );
}
