import { useAuth } from "./AuthGate";
import { chartTypeIds } from "./Charts";
import Controls from "./Controls";
import Layout from "./Layout";
import PaddockNav from "./PaddockNav";
import PaddocksNav from "./PaddocksNav";
import Tour from "./Tour";
import { usePaddockData, usePaddockTabs } from "./hooks";
import interactions from "./interactions";
import { getPaddocksSettings } from "./utils";
import produce from "immer";
import _ from "lodash";
import PropTypes from "prop-types";
import { useState, useMemo } from "react";

export default function AppWithPaddocks({
  paddockState,
  layoutProps,
  showDisclaimerGate,
}) {
  const { user, checkPermission } = useAuth();
    const canEditSettings = checkPermission('CHANGE_ADVANCED_SETTINGS');

  const [chartType, setChartType] = useState(_.nth(chartTypeIds, 1));
  const paddockTabs = usePaddockTabs({
    selectedPaddockId: paddockState.selectedPaddock.id,
  });
  const { isLoading, data } = usePaddockData({
    selectedPaddock: paddockState.selectedPaddock,
  });
  const settings = useMemo(
    () => getPaddocksSettings(paddockState.selectedPaddock),
    [paddockState.selectedPaddock]
  );
  return (
    <>
      {!showDisclaimerGate && <Tour key={paddockTabs.selectedTabId} />}
      <Layout
        {...layoutProps}
        primaryNav={
          <>
          {!canEditSettings && (
            <div className="alert alert-warning">
              You are currently using a trial account with limited access to the irrigation assessment features. The only setting you can adjust is the irrigation water amount in the control panel. To upgrade your account, please contact Steve at <strong>steve@agritechsolutions.com.au</strong>.
            </div>
          )}
          <PaddocksNav
            paddocks={_.values(paddockState.paddocks)}
            selected={paddockState.selectedPaddock.id}
            checkPermission={checkPermission}
            onSelect={(id) => {
              interactions.publish({
                category: "Paddocks Navigation",
                action: "selected",
              });
              paddockState.setSelectedPaddockId(id);
            }}
            onAdd={(paddock) => {
              interactions.publish({
                category: "Paddocks Navigation",
                action: "added",
              });
              paddockState.setPaddocks(
                produce(paddockState.paddocks, (draft) => {
                  draft[paddock.id] = paddock;
                })
              );
              paddockState.setSelectedPaddockId(paddock.id);
            }}
            onRemove={(id) => {
              interactions.publish({
                category: "Paddocks Navigation",
                action: "removed",
              });
              const updatedPaddocks = _.omit(paddockState.paddocks, id);
              paddockState.setSelectedPaddockId(
                _.first(_.keys(updatedPaddocks))
              );
              paddockState.setPaddocks(updatedPaddocks);
            }}
            onChange={(name) => {
              interactions.publish({
                category: "Paddocks Navigation",
                action: "renamed",
              });
              paddockState.setPaddocks(
                produce(paddockState.paddocks, (draft) => {
                  draft[paddockState.selectedPaddock.id].name = name;
                })
              );
            }}
          />
          </>
        }
        controls={
          <Controls
            checkPermission={checkPermission}
            paddock={paddockState.selectedPaddock}
            onPaddockChange={(updatedPaddock) =>
              paddockState.setPaddocks(
                produce(paddockState.paddocks, (draft) => {
                  draft[paddockState.selectedPaddock.id] = updatedPaddock;
                })
              )
            }
            pdfParameters={{
              accessToken: user.signInUserSession.idToken.jwtToken,
              paddockId: paddockState.selectedPaddock.id,
              name: paddockState.selectedPaddock.name,
              chartType,
            }}
          />
        }
        secondaryNav={
          <PaddockNav
            tabs={paddockTabs.paddockTabIds}
            selected={paddockTabs.selectedTabId}
            onSelect={(id) => {
              interactions.publish({
                category: "Paddock Navigation",
                action: "selected",
                label: id,
              });
              paddockTabs.setSelectedTab(id);
            }}
          />
        }
        content={
          <paddockTabs.PaddockTabComponent
            settings={settings}
            scenarios={paddockState.selectedPaddock.scenarios}
            data={data[paddockState.selectedPaddock.id]}
            tab={paddockTabs.selectedTabId}
            chartType={chartType}
            setChartType={setChartType}
            checkPermission={checkPermission}
            isLoading={isLoading}
          />
        }
      />
    </>
  );
}

AppWithPaddocks.propTypes = {
  paddockState: PropTypes.shape({}).isRequired,
  layoutProps: PropTypes.shape({}).isRequired,
  showDisclaimerGate: PropTypes.bool.isRequired,
};
